import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import UseStateContext from './session/UseStateContext'
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Success from './components/Success';
import Header from './components/Header';

const InformationMaterial = () => {

    const { context, setContext } = UseStateContext();

    const [lead, setLead] = useState({});
    const [wantedInformationMaterial, setWantedInformationMaterial] = useState([]);
    const [moreWantedInformationMaterial, setMoreWantedInformationMaterial] = useState("");
    const [interests, setInterests] = useState([]);
    const [otherInterest, setOtherInterest] = useState({
        condition: false,
        text: ""
    })
    const [moreInformation, setMoreInformation] = useState("");
    const [updateData, setUpdateData] = useState({
        address: "",
        condition: ""
    })

    const productAreas = ["Modern Workplace", "Cloud Services", "Netzwerkinfrastruktur", "Pro AV", "Security (digital/physisch)", "Büro- / Geschäftsbedarf"];

    let navigate = useNavigate();

    useEffect(() => {
        if (context.lead) {
            loadLead(context.lead);
        }
    }, [context.lead]);

    const loadLead = async (lead) => {
        setLead(lead);
    }

    const onSubmit = () => {

        let wantedInformationMaterialList = wantedInformationMaterial.join(";");
        let updatedInterests = [...interests];

        if (otherInterest.condition == true) {
            updatedInterests.push(otherInterest.text);
        }

        let interestList = updatedInterests.join(";");

        wantedInformationMaterialList = wantedInformationMaterialList += `;${moreWantedInformationMaterial}`;

        let body = {
            ...lead,
            wantedInformationMaterial: wantedInformationMaterialList,
            interests: interestList,
            moreInformation: moreInformation,
            updateData: updateData.address
        };

        axios.post(`/Lead`, body).then(() => {
            setContext({ lead: lead });
            navigate("/success");
        });
    }

    const updateWantedInformationMaterial = (e, value) => {

        let addElement = e.target.checked;
        let array = [...wantedInformationMaterial];

        if (addElement) {
            array.push(value);
        } else {
            let index = array.findIndex(a => a == value);
            array.splice(index);
        }

        setWantedInformationMaterial(array)
    }

    const updateInterests = (e, value) => {
        let addElement = e.target.checked;
        let array = [...interests];

        if (addElement) {
            array.push(value);
        } else {
            let index = array.findIndex(a => a === value);
            if (index !== -1) {
                array.splice(index, 1); // Remove 1 element at the found index
            }
        }

        setInterests(array);
    }

    let validation =
        ((wantedInformationMaterial.length > 0 || updateData.address.length > 0) || moreWantedInformationMaterial.length > 0)
        && (interests.length > 0 || otherInterest.text.length > 0);

    //let validation =
    //    (wantedInformationMaterial.length > 0 || moreWantedInformationMaterial.length > 0)
    //    && interests.length > 0
    //    && (updateData.address?.length > 0);

    return (
        <>
            <Navbar />
            <Banner />
            <div className='container mt-4 page-container'>
                <Header />

                <Success lead={lead} />

                <form>
                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Fokusbereiche</h3>
                        <p className='text text-secondary fs-6 mb-3'>Der Kunde interessiert sich für folgende Fokusbereiche:<span className='text text-danger'>*</span></p>
                        <div className='mb-2'>
                            {productAreas.map(area => (
                                <div className="form-check mb-3" key={area}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={interests.includes(area)}
                                        onChange={(e) => updateInterests(e, area)}
                                        id={`productAres${area}`}
                                    />
                                    <label className="form-check-label text-secondary p-1 ms-3" htmlFor={`productAres${area}`}>
                                        {area}
                                    </label>
                                </div>
                            ))}
                            <div className="form-check mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={otherInterest.condition}
                                    onChange={(e) => setOtherInterest({ ...otherInterest, condition: !otherInterest.condition })}
                                    id="otherInterest"
                                />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor="otherInterest">
                                    Anderes
                                </label>
                            </div>
                            {otherInterest.condition && <div className="col-12 mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInputValue"
                                    placeholder="Bemerkung *"
                                    name="product"
                                    value={otherInterest.text}
                                    onChange={(e) => setOtherInterest({ ...otherInterest, text: e.target.value })} />
                            </div>}
                        </div>
                    </div>

                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Informationsmaterial</h3>
                        <p className='text text-secondary fs-6 mb-3'>Der Kunde möchte folgendes Informationsmaterial erhalten<span className='text text-danger'>*</span><span className='text text-danger'>*</span></p>
                        <div className='mb-2'>
                            <div className="form-check mb-3">
                                <input
                                    id='checkschulungsunterlagen'
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={wantedInformationMaterial.findIndex(ws => ws == "Schulungsunterlagen") > -1}
                                    onChange={(e) => updateWantedInformationMaterial(e, "Schulungsunterlagen")} />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor='checkschulungsunterlagen'>
                                    Schulungsunterlagen
                                </label>
                            </div>
                            <div className="form-check mb-3">
                                <input className="form-check-input" id='checknewsletter' type="checkbox" checked={wantedInformationMaterial.findIndex(ws => ws == "Newsletter") > -1} onChange={(e) => updateWantedInformationMaterial(e, "Newsletter")} />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor='checknewsletter'>
                                    Newsletter
                                </label>
                            </div>
                            <div className="form-check mb-2 p-0">
                                <input
                                    className="form-control"
                                    type="text" placeholder='Sonstiges, bitte erläutern...'
                                    value={moreWantedInformationMaterial}
                                    onChange={(e) => setMoreWantedInformationMaterial(e.target.value)}
                                    maxLength="220"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Kundendaten (Überprüfung):</h3>
                        <p className='text text-secondary fs-6 mb-3'>Der Kunde möchte folgende Daten updaten (Adresse, Konditionen etc.)<span className='text text-danger'>*</span><span className='text text-danger'>*</span></p>
                        <div className='mb-2'>
                            <div className="form-check mb-2 p-0">
                                <input
                                    className="form-control"
                                    type="text" placeholder='Bitte eingeben…'
                                    value={updateData.address}
                                    onChange={(e) => setUpdateData({ ...updateData, address: e.target.value })}
                                    maxLength="220"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Weitere Informationen</h3>
                        <p className='text text-secondary fs-6 mb-3'>Weitere Bedürfnisse des Kunden oder sonstige Informationen zur Anfrage</p>
                        <div className='mb-2'>
                            <div className="form-check mb-2 p-0">
                                <textarea
                                    className="form-control"
                                    placeholder="Bitte eingeben..."
                                    style={{ height: "100px" }}
                                    value={moreInformation}
                                    onChange={(e) => setMoreInformation(e.target.value)}
                                    maxLength="500"
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <div className='row p-3 mb-5'>
                    <button className='lead-button col-12' onClick={onSubmit} disabled={!validation}>Lead absenden</button>
                </div>
            </div>
        </>
    )
}

export default InformationMaterial