import React, { useState } from 'react'
import UseStateContext from '../session/UseStateContext'
import "../../style.css";
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {

    const { context, resetContext } = UseStateContext();
    const [alert, setAlert] = useState(false);

    const { exhibitor, loggedIn } = context;

    let navigate = useNavigate();
    let location = useLocation();

    const logout = () => {
        axios.post("/Logout").then(() => {
            resetContext();
            navigate("/login");
        })
    }

    console.log(exhibitor);

    return (
        <>
            <div className='container-fluid p-0'>
                <div className='navbar row m-0 p-3'>
                <div className='col-2 text-start m-0 p-0 fs-5'>
                        {(loggedIn && exhibitor?.isAdmin && location?.pathname != "/admin") && <button className='nav-profile-btn me-3 fs-6' onClick={() => navigate("/admin")}><i className="bi bi-person-circle"></i></button>}
                        {(loggedIn && location?.pathname != "/") && <button className='nav-btn fs-6' onClick={() => navigate("/")}><i className="bi bi-qr-code-scan"></i></button>}
                    </div>
                    <div className='col-8 text-center m-0 p-0 navbar-standnumber' onClick={() => navigate(`/`)}>{exhibitor.standNumber && "Eingeloggt als: " + exhibitor.username}</div>
                    <div className='col-2 text-end m-0 p-0'>{loggedIn && <button className='nav-btn' onClick={() => setAlert(true)}>Logout</button>}</div>
                </div>
            </div>
            {alert && <div>
                <div className='content m-3'>
                    <h3 className='text text-dark fw-semibold fs-3 mb-2 text-center'>Logout</h3>

                    <p className='text text-secondary fs-6 text-center'>
                        Wollen Sie sich wirklich abmelden?
                    </p>
                    <div className='text-center'>
                        <button className='ok-btn col-4 me-2' onClick={logout}>Ja</button>
                        <button className='ok-btn col-4' onClick={() => setAlert(false)}>Nein</button>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Navbar