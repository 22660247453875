import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Navbar from './components/Navbar';
import QRScanner from './components/QRScanner';
import Banner from './components/Banner';
import imgBadge1 from "../images/badge-1.png";
import imgBadge2 from "../images/badge-2.png";

const Scan = () => {

    const [code, setCode] = useState();

    let navigate = useNavigate();

    return (
        <>
            <Navbar />
            <Banner />
            <div className='container mt-4 mb-5 page-container'>
                <h1 className='text text-dark fw-semibold fs-3 m-3'>Lead erfassen</h1>
                <div className='content m-3'>
                    <h3 className='text text-dark fw-semibold fs-3 mb-3'>Bereit zum Scannen</h3>
                    <p className='text text-secondary fs-6'>
                        Scannen Sie den QR-Code auf dem Namensschild des Kunden, indem Sie auf den untenstehenden Button tippen.
                    </p>

                    {/* <a className='scan-link' href="intent://camera/#Intent;end">Android: QR-Codeleser herunterladen</a> <br></br>
          <a className='scan-link' href="photos-redirect://">iOS: QR-Scanner herunterladen</a>  <br></br> <br></br>
          <a className='scan-link' href="/contact/609757863771">Contact Formular</a> */}

                    <div className='mb-3'>
                        <QRScanner />
                    </div>

                    <p className='text text-secondary fs-6'>
                        Wenn der Scan auf Ihrem Gerät nicht funktioniert, können Sie die Ticketnummer eingeben, welche oberhalb des QR-Codes auf dem Namensschild des Kunden abgedruckt ist.
                    </p>

                    <div className="input-group mb-3">
                        <input type="number" className="form-control continue-input" pattern={[0 - 9]} placeholder="Ticketnummer eingeben" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => setCode(e.currentTarget.value)} value={code} />
                        <div className="input-group-append">
                            <button className='btn continue-btn ps-4 pe-4' onClick={() => navigate(`contact/${code}`)}>Senden</button>
                        </div>
                    </div>
                </div>

                <div className='content m-3'>
                    <h3 className='text text-dark fw-semibold fs-3 mb-3'>Hilfebereich</h3>

                    <p className='text text-dark fs-6 fw-bold mb-1'>
                        Wo finden Sie die Ticketnummer?
                    </p>

                    <p className='text text-secondary fs-6 mb-2'>
                        Falls der QR-Scan bei Ihnen nicht funktioniert: Die Ticketnummer ist über dem QR-Code auf dem Namensschild aufgedruckt.
                    </p>

                    <img className='img-fluid mb-3' src={imgBadge1}></img>

                    <p className='text text-dark fs-6 fw-bold mb-1'>
                        Der Kunde hat kein Namensschild
                    </p>

                    <p className='text text-secondary fs-6 mb-2'>
                        Wenn der Kunde sein Namensschild nicht zur Hand hat, kann auch der QR-Code auf dem Eintrittsticket gescannt werden, welches dem Kunden per Mail zugestellt wurde.
                    </p>

                    <img className='img-fluid mb-3' src={imgBadge2}></img>

                    <p className='text text-dark fs-6 fw-bold mb-1'>
                        Detaillierter Leitfaden
                    </p>

                    <p className='text text-secondary fs-6 mb-2'>
                        Benötigen Sie weitere Unterstützung? Den detaillierten Leitfaden zur Leadlösung finden Sie auf unserer Website. Scrollen Sie auf der Seite nach unten zum Abschnitt «Messebetrieb»:
                    </p>

                    <a href='https://competec-connect.ch/Termine-und-Downloads/' target='blank'>https://competec-connect.ch/Termine-und-Downloads/</a>
                </div>
            </div>
        </>
    )
}

export default Scan