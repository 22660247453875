import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AccessDenied from "./views/components/AccessDenied";

// session manager
import UseStateContext from "./views/session/UseStateContext";

import Login from "./views/Step10_Login";
import Scan from "./views/Step20_Scan";
import Contact from "./views/Step30_Contact";
import InformationMaterial from "./views/Step35_InformationMaterial";
import ProjectRequest from "./views/Step35_ProjectRequest";
import Success from "./views/Step40_Success";

import Admin from "./views/Step50_Admin";
import PageNotFound from "./views/components/PageNotFound";
import { isUserLoggedIn } from "./Common/ApiService";
import Exhibitor from "./views/Step_05_Exhibitor";
import SessionCheckin from "./views/Step35_SessionCheckin";
import NewCustomer from "./views/Step35_NewCustomer";
import CustomerVisit from "./views/Step35_CustomerVisit";
import ResetPassword from "./views/Step15_ResetPassword";

function App() {

    const { context, setContext, resetContext } = UseStateContext();

    // check if the user is logged in
    const { loggedIn, exhibitor, lead, uniqueKey } = context;

    useEffect(() => {
        if (loggedIn) {
            checkLogin(exhibitor.id)
        }
    }, [])

    const checkLogin = async (id) => {
        const isLoggedIn = await isUserLoggedIn(id);
        if (!isLoggedIn) {
            resetContext();
        }
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/reset/:resetPasswordToken" element={<ResetPassword />} />
                <Route path="/" element={loggedIn ? <Scan /> : <Navigate to="/login" replace />} />
                <Route path="/contact/:uniqueKey" element={(loggedIn) ? <Contact /> : <Navigate to="/denied" replace />} />
                
                <Route path="/information" element={(loggedIn && (lead?.ticketId > 0)) ? <InformationMaterial /> : <Navigate to={"/contact/" + uniqueKey} replace />} />
                <Route path="/project" element={(loggedIn && (lead?.ticketId > 0)) ? <ProjectRequest /> : <Navigate to={"/contact/" + uniqueKey} replace />} />
                <Route path="/customer-visit" element={(loggedIn && (lead?.ticketId > 0)) ? <CustomerVisit /> : <Navigate to={"/contact/" + uniqueKey} replace />} />
                <Route path="/new-customer" element={(loggedIn && (lead?.ticketId > 0)) ? <NewCustomer /> : <Navigate to={"/contact/" + uniqueKey} replace />} />
                <Route path="/session-checkin" element={(loggedIn && (lead?.ticketId > 0)) ? <SessionCheckin /> : <Navigate to={"/contact/" + uniqueKey} replace />} />

                <Route path="/success" element={loggedIn ? <Success /> : <Navigate to="/login" replace />} />

                <Route path="/denied" element={loggedIn ? <AccessDenied /> : <Navigate to="/login" replace />} />
                <Route path="/admin" element={loggedIn ? <Admin /> : <Navigate to="/login" replace />} />

                <Route path="/exhibitor" element={loggedIn ? <Exhibitor /> : <Navigate to="/login" replace />} />
                <Route path="/exhibitor/:exhibitorId" element={loggedIn ? <Exhibitor /> : <Navigate to="/login" replace />} />

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;