import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import { DateTimeManager } from './../Common/DateTimeManager.js';
import axios from 'axios'

import "react-datepicker/dist/react-datepicker.css";

import Navbar from './components/Navbar'
import Banner from './components/Banner'
import Header from './components/Header'
import Success from './components/Success'
import UseStateContext from './session/UseStateContext'

const CustomerVisit = () => {
    const { context, setContext } = UseStateContext();
    const [lead, setLead] = useState({});

    const [deliveryDate, setDeliveryDate] = useState(null);
    const [contactDate, setContactDate] = useState(null);
    const [moreInformation, setMoreInformation] = useState("");
    const [interests, setInterests] = useState([]);
    const [otherInterest, setOtherInterest] = useState({
        condition: false,
        text: ""
    })

    const productAreas = ["Modern Workplace", "Cloud Services", "Netzwerkinfrastruktur", "Pro AV", "Security (digital/physisch)", "Büro- / Geschäftsbedarf"];

    let navigate = useNavigate();

    useEffect(() => {
        if (context.lead) {
            loadLead(context.lead);
        }
    }, [context.lead]);

    const loadLead = async (lead) => {
        setLead(lead);
    }

    const onSubmit = async () => {
        let updatedInterests = [...interests];
        
        if (otherInterest.condition == true) {
            updatedInterests.push(otherInterest.text);
        }

        let interestList = updatedInterests.join(";");

        let body = {
            ...lead,
            deliveryDate: new DateTimeManager(deliveryDate).getDefaultDateFormat(),
            contactDate: new DateTimeManager(contactDate).getDefaultDateFormat(),
            moreInformation: moreInformation,
            interests: interestList
        }

        axios.post(`/Lead`, body).then(() => {
            setContext({ lead: lead });
            navigate("/success");
        });
    }

    const updateInterests = (e, value) => {
        let addElement = e.target.checked;
        let array = [...interests];

        if (addElement) {
            array.push(value);
        } else {
            let index = array.findIndex(a => a === value);
            if (index !== -1) {
                array.splice(index, 1); // Remove 1 element at the found index
            }
        }

        setInterests(array);
    }

    let validation = (deliveryDate != null || contactDate != null)
    && (interests.length > 0 || otherInterest.text.length > 0);

    return (
        <>
            <Navbar />
            <Banner />
            <div className="container mt-4 page-container">
                <Header />
                <Success lead={lead} />
                <form>
                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Fokusbereiche</h3>
                        <p className='text text-secondary fs-6 mb-3'>Der Kunde / Die Kundin interessiert sich für folgende Fokusbereiche:<span className='text text-danger'>*</span></p>
                        <div className='mb-2'>
                            {productAreas.map(area => (
                                <div className="form-check mb-3" key={area}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={interests.includes(area)}
                                        onChange={(e) => updateInterests(e, area)}
                                        id={`productAres${area}`}
                                    />
                                    <label className="form-check-label text-secondary p-1 ms-3" htmlFor={`productAres${area}`}>
                                        {area}
                                    </label>
                                </div>
                            ))}
                            <div className="form-check mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={otherInterest.condition}
                                    onChange={(e) => setOtherInterest({ ...otherInterest, condition: !otherInterest.condition })}
                                    id="otherInterest"
                                />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor="otherInterest">
                                    Anderes
                                </label>
                            </div>
                            {otherInterest.condition && <div className="col-12 mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInputValue"
                                    placeholder="Bemerkung *"
                                    name="product"
                                    value={otherInterest.text}
                                    onChange={(e) => setOtherInterest({ ...otherInterest, text: e.target.value })} />
                            </div>}
                        </div>
                    </div>
                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Termin</h3>
                        <p className='text text-secondary fs-6 mb-3'>Der Kunde / Die Kundin möchte folgenden Termin vereinbaren:<span className='text text-danger'>*</span><span className='text text-danger'>*</span></p>
                        <div className='mb-2'>
                            <DatePicker
                                dateFormat="dd.MM.yy"
                                className="form-control datepicker-input"
                                id="exampleFormControlInput1"
                                selected={deliveryDate}
                                onChange={(date) => setDeliveryDate(date)}
                                autoComplete='off'
                                 />
                        </div>
                    </div>
                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Kontaktaufnahme</h3>
                        <p className='text text-secondary fs-6 mb-3'>Der Kunde / Die Kundin wünscht eine Kontaktaufnahme bis:<span className='text text-danger'>*</span><span className='text text-danger'>*</span></p>
                        <div className='mb-2'>
                            <DatePicker
                                dateFormat="dd.MM.yy"
                                className="form-control datepicker-input"
                                id="exampleFormControlInput1"
                                selected={contactDate}
                                onChange={(date) => setContactDate(date)}
                                autoComplete='off'
                               />
                        </div>
                    </div>
                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Weiteres (Optional)</h3>
                        <p className='text text-secondary fs-6 mb-3'>Weitere Kundenwünsche, die besprochen wurden.</p>
                        <div className='mb-2'>
                            <div className="form-check mb-2 p-0">
                                <textarea
                                    className="form-control"
                                    placeholder="Bitte eingeben..."
                                    style={{ height: "100px" }}
                                    value={moreInformation}
                                    onChange={(e) => setMoreInformation(e.target.value)}
                                    maxLength="500"
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <div className='row p-3 mb-5'>
                    <button className='lead-button col-12' onClick={onSubmit} disabled={!validation}>Lead absenden</button>
                </div>
            </div>
        </>
    )
}

export default CustomerVisit