import React, { useEffect, useState } from 'react'
import UseStateContext from './session/UseStateContext'
import Navbar from './components/Navbar';
import Banner from './components/Banner';

import "../style.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import image from "../images/check-circle-1.svg"

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState({
        password: "",
        confirmPassword: "",
        resetSuccessfully: null,
        resetError: ""
    })

    const location = useLocation();
    let navigate = useNavigate();

    const [email, setEmail] = useState("");

    const { password, confirmPassword, resetSuccessfully, resetError } = newPassword;
    const { resetPasswordToken } = useParams();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setEmail(queryParams.get('email') || '');
    }, [location]);

    const resetPassword = async () => {
        await axios.put(`/Exhibitor/${resetPasswordToken}/Password`, { email: email, password: password })
            .then((res) => {
                setNewPassword({ ...newPassword, resetSuccessfully: true, resetError: "" });
            }).catch((error) => {
                setNewPassword({ ...newPassword, resetSuccessfully: false, resetError: error.response.data });
                console.error(error);
            })
    }

    const onInputChange = (e) => {
        setNewPassword({ ...newPassword, [e.target.name]: e.target.value })
    }

    let validation = password.length > 0 && (password == confirmPassword);

    return (
        <>
            <Navbar />
            {/* <Banner /> */}
            <div className='login container mt-3'>
                {(resetSuccessfully != true) && 
                    <div className='content m-3'>
                        <h3 className='text text-dark fw-semibold fs-3 mb-3'>Passwort zurücksetzen</h3>
                        <div className="form-floating mb-2">
                            <input type="text" className="form-control" id="floatingInputValue" name="email" value={email} disabled />
                            <label htmlFor="floatingInputValue">Email</label>
                        </div>
                        <div className="form-floating mb-2">
                            <input type="password" className="form-control" id="floatingInputValue" name="password" value={password} onChange={onInputChange} />
                            <label htmlFor="floatingInputValue">Passwort</label>
                        </div>
                        <div className="form-floating mb-2">
                            <input type="password" className="form-control" id="floatingInputValue" name="confirmPassword" value={confirmPassword} onChange={onInputChange} />
                            <label htmlFor="floatingInputValue">Passwort bestätigen</label>
                        </div>

                        {/* <p className='text text-danger fs-6'>{error}</p> */}

                        <div className='mb-2'>
                            <button className='button mb-3' disabled={!validation} onClick={resetPassword}>Bestätigen</button>
                        </div>
                    </div>
                }
                {resetSuccessfully == true && <>
                    <div className='content m-3'>
                        <div className='container text-center'>
                            <img className='success-img mb-3' src={image}></img>
                        </div>
                        <h3 className='text text-dark text-center fw-semibold fs-3 mb-3'>Passwort erfolgreich zurückgesetzt</h3>
                    </div>
                    <div className='container text-center'>
                        <button className='success-button' onClick={() => navigate("/login")}>Zum Login</button>
                    </div>
                </>}
            </div>
        </>
    )
}

export default ResetPassword