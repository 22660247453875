import axios from "axios";

export const getLeadById = async (id) => {
    try {
      const response = await axios.get(`/Lead/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
  
  export const getLeadByTicketId = async (ticketId) => {
    try {
      const response = await axios.get(`/Lead/ByTicket/${ticketId}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
  
  export const createLead = async (body) => {
    try {
      const response = await axios.post("/Lead", body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
  
  export const updateLead = async (body) => {
    try {
      const response = await axios.put(`/Lead`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };

  export const getLeads = async () => {
    try {
      const response = await axios.get(`/Leads`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
  
  export const deleteLead = async (leadId) => {
    try {
      const response = await axios.delete(`/Lead/${leadId}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  export const getExhibitorById = async (id) => {
    try {
      const response = await axios.get(`/Exhibitor/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
  
  export const getAllExhibitors = async () => {
    try {
      const response = await axios.get(`/Exhibitors`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
  
  export const createExhibitor = async (body) => {
    try {
      const response = await axios.post("/Exhibitor", body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
  
  export const updateExhibitor = async (body, updatePassword) => {
    try {
      const response = await axios.put(`/Exhibitor?updatePassword=${updatePassword}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
  
  export const isUserLoggedIn = async (id) => {
    try {
      const response = await axios.get(`/Exhibitor/${id}`);
      return true;
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return false;
      }
      return error.response.data;
    }
  };
  