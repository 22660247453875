import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import { DateTimeManager } from './../Common/DateTimeManager.js';
import axios from 'axios'

import "react-datepicker/dist/react-datepicker.css";

import Navbar from './components/Navbar'
import Banner from './components/Banner'
import Header from './components/Header'
import Success from './components/Success'
import UseStateContext from './session/UseStateContext'

const SessionCheckin = () => {
    const { context, setContext } = UseStateContext();
    const [lead, setLead] = useState({});
    const [selectedSession, setSelectedSessions] = useState([]);

    const sessionItems = 
        ["Apple Switzerland", "Axis Communications", "Barco", "BenQ Deutschland GmbH", "CONNECT-Academy - KI",
            "Dell Technologies", "EPOS Germany GmbH", "HP Inc", "Huawei", "Jabra", "Lenovo",
            "Microsoft Schweiz GmbH - The Future of Work ", "Microsoft Schweiz GmbH - Windows Server 2025",
            "Mitel Schweiz AG", "NAKIVO", "NVIDIA", "PureLink GmbH", "Schneider Electric Schweiz AG",
            "SCORE! - Carpathia AG", "SCORE! - Competec", "SCORE! - Helion Energy AG", "SCORE! - Meier Tobler",
            "SCORE! - Quatico Solutions AG", "SCORE! - R. Nussbaum AG", "Snom Technology GmbH", "Synology GmbH",
            "TP-Link", "Veeam Software", "Zyxel Networks"]


    let navigate = useNavigate();

    useEffect(() => {
        if (context.lead) {
            loadLead(context.lead);
        }
    }, [context.lead]);

    const loadLead = async (lead) => {
        setLead(lead);
    }

    const onSubmit = async () => {
        let body = {
            ...lead,
            session: selectedSession
        }

        axios.post(`/Lead`, body).then(() => {
            setContext({ lead: lead });
            navigate("/success");
        });
    }

    let validation = selectedSession?.length > 0;

    return (
        <>
            <Navbar />
            <Banner />
            <div className="container mt-4 page-container">
                <Header />
                <Success lead={lead} />
                <form>
                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Session<span className='text text-danger'>*</span></h3>
                        <div className='mb-2'>
                            <div className="form-floating">
                                <select className="form-select" style={{ paddingLeft: "12px" }} id="floatingSelect" value={selectedSession} onChange={(e) => setSelectedSessions(e.target.value)}>
                                <option className='ps-2' value=""></option>
                                    {sessionItems.map(session => (
                                        <option className='ps-2' value={session}>{session}</option>
                                    ))}
                                </select>
                                <label htmlFor="floatingSelect">Session</label>
                            </div>

                        </div>
                    </div>
                </form>

                <div className='row p-3 mb-5'>
                    <button className='lead-button col-12' onClick={onSubmit} disabled={!validation}>Lead absenden</button>
                </div>
            </div>
        </>
    )
}

export default SessionCheckin