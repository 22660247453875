import React, { useEffect, useState } from 'react'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { DateTimeManager } from './../Common/DateTimeManager.js';
import { useNavigate } from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";

import UseStateContext from './session/UseStateContext'
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Success from './components/Success';
import Header from './components/Header';

const ProjectRequest = () => {
    const { context, setContext } = UseStateContext();

    const [lead, setLead] = useState({});
    const [deliveryDate, setDeliveryDate] = useState(null);

    const [products, setProducts] = useState([{ article: "", quantity: "", description: "" }]);

    const manufacturerItems =  ["2N", "3M", "Acer", "Acronis", "Alcatel-Lucent Enterprise"
        , "Allianz Digitale Sicherheit Schweiz ADSS", "Alltron Hub", "APC", "ASUS", "AVer Europe"
        , "AVM", "Axis Communications", "Barco", "BenQ", "BRACK.CH Business Hub", "Brother (Schweiz) AG"
        , "Canon Schweiz AG", "Club 3D", "CONNECT Cloud Service Plattform", "CONNECT Service Datacenter Solutions", "CONNECT Service Physical Security"
        , "CONNECT Service Pro AV & Multimedia", "CONNECT Telco & UCC", "CyberSeal"
        , "DATA Security AG - Datenschutzgesetz", "Dell Technologies", "DELOCK", "DICOTA SCHWEIZ AG"
        , "Die Schweizerische Post", "DJI", "easescreen", "Eaton", "EIZO AG"
        , "EPOS Switzerland AG", "Epson", "ERAZER MEDION", "ESET", "FLOKK", "Gigaset Technologies"
        , "Hanwha Vision Europe", "Heidi Pay", "Hewlett Packard Enterprise", "HP Inc.", "HPE Aruba", "Huawei", "ICY BOX", "Jabra"
        , "KENSINGTON", "Kingston Technology GmbH", "KYOCERA Document Solutions","LC Power","Lenovo","LG Electronics GmbH","Lightwin","LMP"
        , "LOGITECH", "Metabo", "Microsoft Schweiz GmbH", "Mitel Schweiz AG"
        , "NAKIVO", "Norton & Avast", "onit. und weitere Eigenmarken", "ONLINE USV-Systeme AG"
        , "OpenText Cybersecurity", "Panasonic Toughbook", "PanzerGlass", "PATCHBOX", "PATCHSEE"
        , "PEACH", "Peerless-AV", "PFU", "Philips", "Planzer Transport AG", "PNY", "PureLink"
        , "QNAP GmbH", "SAMSUNG", "SAMSUNG TV & Mobile Division", "Seagate", "Server and Options", "Sharp"
        , "Sicotec AG", "SLIM", "Snom Technology GmbH", "SONY", "Sony Professional", "Suprema", "Synology GmbH"
        , "Targus Switzerland GmbH", "Teltonika", "TP-Link", "Trust"
        , "Tungsten Automation", "Ubiquiti", "Veeam Software", "Wirewin", "xFusion", "Zyxel Networks"];


    const [isAdmin] = useState(sessionStorage.getItem("isAdmin"));
    const [isEmployee] = useState(sessionStorage.getItem("isEmployee"));

    const {
        projectDescription,
        reference,
        budget,
        isProject,
        projectType,
        manufacturer,
        deliveryDateString
    } = lead;

    let navigate = useNavigate();

    useEffect(() => {
        if (context.lead) {
            loadLead(context.lead);
        }
    }, [context.lead]);

    const loadLead = async (lead) => {
        setLead(lead);
    }

    const onInputChange = (e) => {
        setLead({ ...lead, [e.target.name]: e.target.value });
    };

    const onSubmit = () => {

        let productList = products.map(product => `${product.article},${product.quantity},${product.description}`).join(";");

        let body = {
            ...lead,
            projectDescription: projectDescription,
            reference: reference,
            budget: budget,
            productList: productList,
            deliveryDateString: deliveryDateString
            //deliveryDate: new DateTimeManager(deliveryDate).getDefaultDateFormat()
        };

        axios.post(`/Lead`, body).then(() => {
            setContext({ lead: lead });
            navigate("/success");
        });
    }

    const productList = () => {
        return (
            <>
                {
                    products.map((product, index) => (
                        <div className="row">
                            <div className="col-12 text text-secondary mb-2">Artikel<span className='text text-danger'>*</span></div>
                            <div className="col-12 mb-3">
                                <input type="text" className="form-control" id="floatingInputValue" name="product" value={product?.article}
                                    onChange={(e) => {
                                        let updatedList = [...products];
                                        updatedList[index].article = e.target.value;
                                        setProducts(updatedList);
                                    }}
                                    maxLength="200" />
                            </div>
                            <div className="col-12 text text-secondary mb-2">Stückzahl<span className='text text-danger'>*</span></div>
                            <div className="col-12 mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInputValue"
                                    name="product"
                                    value={product?.quantity}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        if (!isNaN(newValue)) {
                                            let updatedList = [...products];
                                            updatedList[index].quantity = newValue;
                                            setProducts(updatedList);
                                        }
                                    }}
                                    pattern="[0-9]*" />
                            </div>
                            <div className="col-12 text text-secondary mb-2">Bemerkung</div>
                            <div className="col-12 mb-3">
                                <input type="text" className="form-control" id="floatingInputValue" name="product" value={product?.description}
                                    onChange={(e) => {
                                        let updatedList = [...products];
                                        updatedList[index].description = e.target.value;
                                        setProducts(updatedList);
                                    }}
                                    maxLength="255" />
                            </div>
                            <div className="col-12 mb-2">
                                <button className='add-btn'
                                    onClick={() => {
                                        let updatedList = [...products];
                                        updatedList.splice(index, 1);
                                        setProducts(updatedList);
                                    }}>
                                    <i className="bi bi-trash3 fs-4"></i></button>
                            </div>
                            {(index + 1) != products.length && <hr></hr>}
                        </div>
                    ))
                }
            </>
        )
    }

    let productValidation = products.every(product => (
        product?.article.length > 0 &&
        product?.quantity > 0
    ));

    let validation = false;

    if (isAdmin === 'true' || isEmployee === 'true') {
        validation = (
            products.length > 0 &&
            manufacturer?.length > 0 &&
            productValidation
        );
        
    } else {
        validation = (
            products.length > 0 &&
            productValidation
        );
    }

    if (isProject && !(budget?.length > 0)) {
        validation = false;
    }

    return (
        <>
            <Navbar />
            <Banner />
            <div className='container mt-4 page-container'>
                <Header />
                <Success lead={lead} />

                <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Offerte oder Projekt<span className='text text-danger'>*</span></h3>
                    <div className='mb-1'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={!isProject}
                                onChange={() => setLead({ ...lead, isProject: false })}
                                id="offerte"
                            />
                            <label className="form-check-label text-secondary p-1 ms-3" htmlFor="offerte">
                                Offerte
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isProject}
                                onChange={() => setLead({ ...lead, isProject: true })}
                                id="project"
                            />
                            <label className="form-check-label text-secondary p-1 ms-3" htmlFor="project">
                                Projekt
                            </label>
                        </div>
                    </div>
                </div>

                <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Art der Anfrage<span className='text text-danger'>*</span></h3>
                    <div className='mb-1'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={projectType == "Projektpreis"}
                                onChange={() => setLead({ ...lead, projectType: "Projektpreis" })}
                                id="Projektpreis"
                            />
                            <label className="form-check-label text-secondary p-1 ms-3" htmlFor="Projektpreis">
                                Projektpreis (Offerte)
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={projectType == "Projektanfrage"}
                                onChange={() => setLead({ ...lead, projectType: "Projektanfrage" })}
                                id="Projektanfrage"
                            />
                            <label className="form-check-label text-secondary p-1 ms-3" htmlFor="Projektanfrage">
                                Projektanfrage (Lösung)
                            </label>
                        </div>
                    </div>
                </div>

                {/* <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Projektbeschrieb<span className='text text-danger'>*</span></h3>
                    <div className='mb-2'>
                        <div className="form-check mb-2 p-0">
                            <textarea className="form-control"
                                placeholder="Bitte beschreiben Sie das Projekt..."
                                name='projectDescription'
                                style={{ height: "100px" }}
                                value={projectDescription} onChange={onInputChange}
                                maxLength="500"
                            />
                        </div>
                    </div>
                </div> */}

                {isProject && <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Budget</h3>
                    <p className='text text-secondary fs-6 mb-3'>Wie ist das geplante Budget?<span className='text text-danger'>*</span></p>
                    <div className='mb-2'>
                        <div className='row'>
                            <div className="col-12">
                                <div className="form-floating">
                                    <select className="form-select" style={{ paddingLeft: "12px" }} id="floatingSelect" value={budget} name='budget' onChange={onInputChange}>
                                        <option className='ps-2' value=""></option>
                                        <option className='ps-2' value="Bis 5000.- CHF">Bis 5000.- CHF</option>
                                        <option value="Bis 10'000.- CHF">Bis 10'000.- CHF</option>
                                        <option value="Über 10'000.- CHF">Über 10'000.- CHF</option>
                                    </select>
                                    <label htmlFor="floatingSelect">Budget</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {(isAdmin === 'true' || isEmployee === 'true') &&
                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Hersteller<span className='text text-danger'>*</span></h3>
                        <div className='mb-2'>
                            <div className='row'>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <select className="form-select" style={{ paddingLeft: "12px" }} id="floatingSelect" value={manufacturer} name='manufacturer' onChange={onInputChange}>
                                            <option className='ps-2' value=""></option>
                                            {manufacturerItems.map((manufacturer) => (
                                                <option className='ps-2' value={manufacturer}>{manufacturer}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="floatingSelect">Hersteller</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Referenz/Infos</h3>
                    <p className='text text-secondary fs-6 mb-3'>Hier können eine Projektreferenz oder weiterführende Infos erfasst werden.</p>
                    <div className='mb-2'>
                        <div className='row'>
                            <div className="col-12">
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInputValue"
                                        name="reference"
                                        value={reference}
                                        onChange={onInputChange}
                                        maxLength="200" />
                                    <label htmlFor="floatingInputValue">Referenz/Infos</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Gewünschter Liefertermin</h3>
                    <p className='text text-secondary fs-6 mb-3'>In welcher Kalendarwoche/in welchem Monat ist die Lieferung gewünscht?</p>
                    <div className='mb-2'>
                        <div className='row'>
                            <div className='col-12'>
                                <div>
                                    <label htmlFor="exampleFormControlInput1" className="form-label text text-secondary fs-6 mb-3'">Gewünschter Liefertermin</label><br></br>
                                    {/*<DatePicker*/}
                                    {/*    dateFormat="dd.MM.yy"*/}
                                    {/*    className="form-control datepicker-input"*/}
                                    {/*    id="exampleFormControlInput1"*/}
                                    {/*    selected={deliveryDate}*/}
                                    {/*    onChange={(date) => setDeliveryDate(date)}*/}
                                    {/*    autoComplete='off'*/}
                                    {/*    onKeyDown={(e) => {*/}
                                    {/*        e.preventDefault();*/}
                                    {/*    }} />*/}
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="deliveryDateString"
                                        name="deliveryDateString"
                                        value={deliveryDateString}
                                        onChange={onInputChange}
                                        maxLength="200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

                <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Artikel (Bestellung)<span className='text text-danger'>*</span></h3>
                    <div className='row'>
                        <div className='col-10'>
                            <p className='text text-secondary fs-6 mb-3'>
                                Welche Artikel sollen in welcher Stückzahl offeriert werden?<span className='text text-danger'>*</span>
                            </p>
                        </div>
                        <div className='col-2 text-end'>
                            <button className='add-btn'
                                onClick={() => setProducts([...products, { article: "", quantity: "", description: "" }])}>
                                <i className="bi bi-plus-square fs-4"></i>
                            </button>
                        </div>
                    </div>
                    <div className='mb-0'>
                        {productList()}
                    </div>
                </div>

                <div className='row p-3 mb-5'>
                    <button className='lead-button col-12' onClick={onSubmit} disabled={!validation}>Lead absenden</button>
                </div>
            </div>
        </>
    )
}

export default ProjectRequest