import React from 'react'
import Navbar from './components/Navbar';

import image from "../images/check-circle-1.svg"
import { useNavigate } from 'react-router-dom';
import UseStateContext from './session/UseStateContext';

const Success = () => {

    const { context, setContext } = UseStateContext();

    let navigate = useNavigate();

    const onSubmit = () => {
        setContext({lead: {}})
        navigate("/")
    }

    return (
        <>
            <Navbar />
            {/* <Banner /> */}
            <div className='container mt-4 page-container'>
                <div className='content m-3'>
                    <div className='container text-center'>
                        <img className='success-img mb-3' src={image}></img>
                    </div>
                    <h3 className='text text-dark text-center fw-semibold fs-3 mb-3'>Lead erfolgreich erfasst</h3>
                </div>
                <div className='container text-center'>
                    <button className='success-button' onClick={onSubmit}>Neuen Lead erfassen</button>
                </div>
            </div>
        </>
    )
}

export default Success