import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Loading from './components/Loading';
import { createExhibitor, getExhibitorById, updateExhibitor } from '../Common/ApiService';

const Exhibitor = () => {
    const [exhibitor, setExhibitor] = useState({
        name: "",
        module: "",
        username: "",
        standNumber: "",
        password: "",
        isAdmin: false,
        isEmployee: false,
    });

    const [updatePassword, setUpdatePassword] = useState(false);

    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(true);

    const { exhibitorId } = useParams();

    let navigate = useNavigate();

    useEffect(() => {
        if (exhibitorId > 0) {
            loadExhibitor(exhibitorId);
        } else {
            setEditMode(false);
        }
    }, [exhibitorId]);

    const loadExhibitor = async (id) => {
        try {
            const updatedExhibitor = await getExhibitorById(id);

            if (updatedExhibitor.id > 0) {
                setExhibitor({ ...exhibitor, ...updatedExhibitor, password: "" });
            } else {
                setEditMode(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const onSubmit = async () => {
        const exhibitorId = editMode ? await updateExhibitor(exhibitor, updatePassword) : await createExhibitor(exhibitor);

        if (exhibitorId > 0) {
            navigate("/admin");
        }
    }

    const onInputChange = (e) => {
        setExhibitor({ ...exhibitor, [e.target.name]: e.target.value });
    }

    return (
        <>
            <Navbar />
            <Banner />
            {loading ? <Loading /> :
                <div className='page-container container mt-4 mb-5'>
                    <>
                        <h1 className='text text-dark fw-semibold fs-3 m-3'>Aussteller bearbeiten</h1>
                        <div className='content m-3'>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Firmenname' name='name' value={exhibitor.name} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Firmenname</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Benutzername' name='username' value={exhibitor.username} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Benutzername</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Interne Nummer' name='standNumber' value={exhibitor.standNumber} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Interne Nummer</label>
                            </div>
                            {!editMode && <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Passwort' name='password' value={exhibitor.password} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Passwort</label>
                            </div>}
                            {(updatePassword && editMode) && <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Neues Passwort' name='password' value={exhibitor.password} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Neues Passwort</label>
                            </div>}
                            {editMode && <div className="mb-3">
                                <div className="form-check col-12">
                                    <input className="form-check-input" type="checkbox" checked={updatePassword} id="flexCheckDefault" onChange={() => setUpdatePassword(!updatePassword)} />
                                    <label className="form-check-label p-1 ms-3" htmlFor="flexCheckDefault">
                                        Neues Passwort festlegen
                                    </label>
                                </div>
                            </div>}
                            <div className="mb-3">
                                <div className="form-check col-12">
                                    <input className="form-check-input" type="checkbox" checked={exhibitor.isAdmin} id="flexCheckDefault2" onChange={() => setExhibitor({ ...exhibitor, isAdmin: !exhibitor.isAdmin })} />
                                    <label className="form-check-label p-1 ms-3" htmlFor="flexCheckDefault2">
                                        Ist Admin
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="form-check col-12">
                                    <input className="form-check-input" type="checkbox" checked={exhibitor.isEmployee} id="flexCheckDefault3" onChange={() => setExhibitor({ ...exhibitor, isEmployee: !exhibitor.isEmployee })} />
                                    <label className="form-check-label p-1 ms-3" htmlFor="flexCheckDefault3">
                                        Beim Einloggen nicht nach dem Namen fragen
                                    </label>
                                </div>
                            </div>
                            <div className='row buttons-div'>
                                <div className="col-6 text-start">
                                    <button className='button' onClick={() => navigate(-1)}>Zurück</button>
                                </div>
                                <div className="col-6 text-end">
                                    <button className='button' onClick={onSubmit}>Speichern</button>
                                </div>
                            </div>
                        </div>
                    </>
                </div>}
        </>
    )
}

export default Exhibitor