import React, { useEffect, useState } from 'react'
import UseStateContext from './session/UseStateContext'
import Navbar from './components/Navbar';
import Banner from './components/Banner';

import "../style.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {

    const { context, setContext } = UseStateContext();

    const [user, setUser] = useState({});
    const [exhibitor, setExhibitor] = useState({});
    const [loggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState("");

    const { username, password } = user;

    const [forgotPassword, setForgotPassword] = useState({
        condition: false,
        username: "",
        codeSentSuccessfully: null,
        codeSentError: ""
    });

    const [scanner, setScanner] = useState("");

    let navigate = useNavigate();

    const login = () => {
        axios.post(`/Login?username=${username}&password=${password}&returnUrl=/`).then((res) => {
            let theExhibitor = res.data;

            sessionStorage.setItem("isAdmin", theExhibitor.isAdmin);
            sessionStorage.setItem("isEmployee", theExhibitor.isEmployee);

            if (theExhibitor.isEmployee == true) {
                finishLogin(theExhibitor.name, theExhibitor);
            } else {
                setExhibitor(theExhibitor);
                setIsLoggedIn(true);
                setError("");
            }

        }).catch((error) => {
            setIsLoggedIn(false);
            setError(error.response.data);
        });
    }

    const finishLogin = (loginScanner, loginExhibitor) => {
        setContext({
            loggedIn: true,
            scanner: loginScanner,
            exhibitor: loginExhibitor
        });

        navigate("/");
    }

    const sendResetPasswordEmail = async () => {
        await axios.post(`Exhibitor/ResetPassword?email=${forgotPassword.username}`).then((res) => {
            setForgotPassword({ ...forgotPassword, codeSentSuccessfully: true })
        }).catch((error) => {
            console.error(error);
            setForgotPassword({ ...forgotPassword, codeSentSuccessfully: false, codeSentError: error.response.data })
        })
    }

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    let validation = scanner.length > 0;

    return (
        <>
            <Navbar />
            {/* <Banner /> */}
            <div className='login container mt-3'>
                {(!loggedIn && !forgotPassword.condition) &&
                    <div className='content m-3'>
                        <h3 className='text text-dark fw-semibold fs-3 mb-3'>Anmelden</h3>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInputValue" name="username" value={username} onChange={onInputChange} />
                            <label htmlFor="floatingSelect">Benutzername</label>
                        </div>
                        <div className="form-floating mb-2">
                            <input type="password" className="form-control" id="floatingInputValue" name="password" value={password} onChange={onInputChange} />
                            <label htmlFor="floatingInputValue">Passwort</label>
                        </div>

                        <p className='text text-danger fs-6'>{error}</p>

                        {/*<div className="mb-3">*/}
                        {/*    <a className='mb-3 forgot-password-link' onClick={() => setForgotPassword({ ...forgotPassword, condition: true })}>Passwort vergessen?</a>*/}
                        {/*</div>*/}

                        <div className='mb-2'>
                            <button className='button mb-3' onClick={login}>Bestätigen</button>
                        </div>

                        <div className='row'>
                            <div className="col-1">
                                <i className="bi bi-info-circle text-secondary"></i>
                            </div>
                            <div className="col-11">
                                <p className='text text-secondary fs-6' style={{ margin: "auto" }}>
                                    <b>Information für Aussteller (Extern):</b><br />
                                    Ihren Benutzername und das Passwort können Sie der Anleitung entnehmen, welche den Standverantwortlichen vorgängig per Mail zugestellt wurde.
                                    Sollten Sie Ihre Login-Daten nicht erhalten haben, melden Sie sich bitte im Messebüro.<br />
                                </p>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-1">
                                <i className="bi bi-info-circle text-secondary"></i>
                            </div>
                            <div className="col-11">
                                <p className='text text-secondary fs-6' style={{ margin: "auto" }}>
                                    <b>Information für Competec-Mitarbeitende (Intern):</b><br />
                                    Allen Competec-Mitarbeitenden wurde ein persönlicher Login erstellt. Konsultiere hierfür bitte die Anleitung auf unserer interne Informationsseite.<br />
                                    Sollte Dir kein Account erstellt worden sein, melde Dich bitte im Messebüro.
                                </p>
                            </div>
                        </div>
                    </div>
                }
                {loggedIn &&
                    <div className='content m-3'>
                        <h3 className='text text-dark fw-semibold fs-3 mb-2'>Ihr Name</h3>
                        <p className='text text-secondary fs-6'>
                            Bitte geben Sie Ihren Namen ein, damit die Leads damit in Verbindung gebracht werden können. Der Name ist für die Dauer des Logins gültig.
                        </p>
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" id="floatingInputValue" name="scanner" value={scanner} onChange={(e) => setScanner(e.currentTarget.value)} />
                            <label htmlFor="floatingInputValue">Vorname und Name</label>
                        </div>

                        <p className='text text-danger fs-6'>{error}</p>

                        <div className='mb-2'>
                            <button className='button' onClick={() => finishLogin(scanner, exhibitor)} disabled={!validation}>Bestätigen</button>
                        </div>
                    </div>
                }
                {forgotPassword.condition &&
                    <div className='content m-3'>
                        <h3 className='text text-dark fw-semibold fs-3 mb-3'>Passwort zurücksetzen</h3>
                        <p className='text text-secondary fs-6'>
                            Geben Sie die E-Mail-Adresse oder den Benutzernamen ein, der mit Ihrem Konto verbunden ist. Wir senden Ihnen einen Link, um Ihr Passwort zurückzusetzen.
                        </p>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInputValue" name="username"
                                value={forgotPassword.username}
                                onChange={(e) => setForgotPassword({ ...forgotPassword, username: e.target.value })} />
                            <label htmlFor="floatingInputValue">E-Mail-Adresse</label>
                        </div>
                        <div>
                            <button className='button mb-3' onClick={sendResetPasswordEmail}>Link senden</button>
                        </div>
                        {forgotPassword.codeSentSuccessfully == true ?
                            <p className='text text-success mb-0'>Link erfolgreich gesendet</p>
                            : forgotPassword.codeSentSuccessfully == false ?
                                <p className='text text-danger mb-0'>{forgotPassword.codeSentError}</p> : <></>}
                    </div>
                }
            </div>
        </>
    )
}

export default Login