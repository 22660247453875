import React from 'react'
import "../../style.css";

import banner from "../../images/banner.png"

const Banner = () => {
    return (
        <div className='container banner-container'>
            <div className='row m-0'>
                <div className='col-12 fill p-0'>
                    <img className='img-fluid' src={banner}></img>
                </div>
            </div>
        </div>
    )
}

export default Banner