import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import UseStateContext from './session/UseStateContext'
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Success from './components/Success';
import Header from './components/Header';

const NewCustomer = () => {
    const { context, setContext } = UseStateContext();

    const [lead, setLead] = useState({});
    const [interests, setInterests] = useState([]);
    const [otherInterest, setOtherInterest] = useState({
        condition: false,
        text: ""
    })

    const [moreInformation, setMoreInformation] = useState("");
    const [data, setData] = useState({
        company: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
    })

    const productAreas = ["Modern Workplace", "Cloud Services", "Netzwerkinfrastruktur", "Pro AV", "Security (digital/physisch)", "Büro- / Geschäftsbedarf"];

    let navigate = useNavigate();

    useEffect(() => {
        if (context.lead) {
            loadLead(context.lead);
        }
    }, [context.lead]);

    const loadLead = async (lead) => {
        setLead(lead);
    }

    const onSubmit = () => {
        let updatedInterests = [...interests];

        if (otherInterest.condition == true) {
            updatedInterests.push(otherInterest.text);
        }

        let interestList = updatedInterests.join(";");

        let body = {
            ...lead,
            interests: interestList,
            moreInformation: moreInformation,
            data: `${data.company};${data.firstname};${data.lastname};${data.email};${data.phone}`
        };

        axios.post(`/Lead`, body).then(() => {
            setContext({ lead: lead });
            navigate("/success");
        });
    }

    const updateInterests = (e, value) => {
        let addElement = e.target.checked;
        let array = [...interests];

        if (addElement) {
            array.push(value);
        } else {
            let index = array.findIndex(a => a === value);
            if (index !== -1) {
                array.splice(index, 1); // Remove 1 element at the found index
            }
        }

        setInterests(array);
    }

    let validation =
        (interests.length > 0 || otherInterest.text.length > 0)
        && (data.company?.length > 0 && data.firstname?.length > 0 && data.lastname?.length > 0 && data.email?.length > 0 && data.phone?.length > 0);

    return (
        <>
            <Navbar />
            <Banner />
            <div className='container mt-4 page-container'>
                <Header />

                <Success lead={lead} />

                <form>
                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Fokusbereiche</h3>
                        <p className='text text-secondary fs-6 mb-3'>Der Kunde interessiert sich für folgende Fokusbereiche:<span className='text text-danger'>*</span></p>
                        <div className='mb-2'>
                            {productAreas.map(area => (
                                <div className="form-check mb-3" key={area}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={interests.includes(area)}
                                        onChange={(e) => updateInterests(e, area)}
                                        id={`productAres${area}`}
                                    />
                                    <label className="form-check-label text-secondary p-1 ms-3" htmlFor={`productAres${area}`}>
                                        {area}
                                    </label>
                                </div>
                            ))}
                            <div className="form-check mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={otherInterest.condition}
                                    onChange={(e) => setOtherInterest({ ...otherInterest, condition: !otherInterest.condition })}
                                    id="otherInterest"
                                />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor="otherInterest">
                                    Anderes
                                </label>
                            </div>
                            {otherInterest.condition && <div className="col-12 mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInputValue"
                                    placeholder="Bemerkung *"
                                    name="product"
                                    value={otherInterest.text}
                                    onChange={(e) => setOtherInterest({ ...otherInterest, text: e.target.value })} />
                            </div>}
                        </div>
                    </div>

                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Stammdaten Neukunde</h3>
                        <p className='text text-secondary fs-6 mb-3'>Erfassen Sie die Daten in nachfolgenden Feldern:<span className='text text-danger'>*</span></p>
                        <div className='mb-2'>
                            <div className="form-check mb-2 p-0">
                                <input
                                    className="form-control"
                                    type="text" placeholder='Firmenname'
                                    value={data.company}
                                    onChange={(e) => setData({ ...data, company: e.target.value })}
                                    maxLength="220"
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <div className="form-check mb-2 p-0">
                                <input
                                    className="form-control"
                                    type="text" placeholder='Vorname'
                                    value={data.firstname}
                                    onChange={(e) => setData({ ...data, firstname: e.target.value })}
                                    maxLength="220"
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <div className="form-check mb-2 p-0">
                                <input
                                    className="form-control"
                                    type="text" placeholder='Nachname'
                                    value={data.lastname}
                                    onChange={(e) => setData({ ...data, lastname: e.target.value })}
                                    maxLength="220"
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <div className="form-check mb-2 p-0">
                                <input
                                    className="form-control"
                                    type="text" placeholder='E-Mail Adresse'
                                    value={data.email}
                                    onChange={(e) => setData({ ...data, email: e.target.value })}
                                    maxLength="220"
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <div className="form-check mb-2 p-0">
                                <input
                                    className="form-control"
                                    type="text" placeholder='Telefonnummer'
                                    value={data.phone}
                                    onChange={(e) => setData({ ...data, phone: e.target.value })}
                                    maxLength="220"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='content m-3 mb-4'>
                        <h3 className='text text-dark fw-semibold fs-5 mb-3'>Weitere Informationen</h3>
                        <p className='text text-secondary fs-6 mb-3'>Weitere Bedürfnisse des Kunden oder sonstige Informationen zur Anfrage</p>
                        <div className='mb-2'>
                            <div className="form-check mb-2 p-0">
                                <textarea
                                    className="form-control"
                                    placeholder="Bitte eingeben..."
                                    style={{ height: "100px" }}
                                    value={moreInformation}
                                    onChange={(e) => setMoreInformation(e.target.value)}
                                    maxLength="500"
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <div className='row p-3 mb-5'>
                    <button className='lead-button col-12' onClick={onSubmit} disabled={!validation}>Lead absenden</button>
                </div>
            </div>
        </>
    )
}

export default NewCustomer