import React, { useState } from 'react'
import UseStateContext from '../session/UseStateContext';
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const { context, setContext } = UseStateContext();
    const [alert, setAlert] = useState(false);

    let navigate = useNavigate();

    const onCancel = () => {
        setContext({ lead: {}, uniqueKey: 0, requestType: "" });
        navigate("/");
    }

    return (
        <>
            <div className='row m-3 p-0'>
                <div className='col-6 p-0'>
                    <h1 className='text text-dark fw-semibold fs-3'>Lead erfassen</h1>
                </div>
                <div className='col-6 text-end p-0'>
                    <button className='nav-btn text-end' onClick={() => setAlert(true)}>Abbruch</button>
                </div>
            </div>

            {alert && <div>
                <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-3 mb-2 text-center'>Abbruch</h3>

                    <p className='text text-secondary fs-6 text-center'>
                        Wollen Sie wirklich abbrechen?
                    </p>
                    <div className='text-center'>
                        <button className='ok-btn col-4 me-2' onClick={onCancel}>Ja</button>
                        <button className='ok-btn col-4' onClick={() => setAlert(false)}>Nein</button>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Header