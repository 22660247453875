import React, { useEffect, useState } from 'react'
import Navbar from './components/Navbar';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { DateTimeManager } from './../Common/DateTimeManager.js';
import { useNavigate } from 'react-router-dom';
import { deleteLead, getAllExhibitors, getLeads } from '../Common/ApiService.js';
import { api, getTicketById } from 'ticketino-api-client';
import UseStateContext from './session/UseStateContext.jsx';
import Loading from './components/Loading.jsx';

const Admin = () => {
    const { context: { exhibitor }, setContext } = UseStateContext();

    const [exhibitors, setExhibitors] = useState([]);
    const [leads, setLeads] = useState([]);

    const [selectedTab, setSelectedTab] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    let navigate = useNavigate();

    const [fromDate, setFromDate] = useState(() => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() - 10);
        return newDate;
    });

    useEffect(() => {
        if (selectedTab == 0) {
            if (exhibitor.isAdmin) {
                loadExhibitors();
            }
        }

        if (selectedTab == 1) {
            loadLeads();
        }
    }, [selectedTab])

    const [toDate, setToDate] = useState(new Date());

    const updateToken = async () => {
        await axios.get("Ticketino/Token").then(async (res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
        }).catch(() => {
            setError(true);
        })
    }

    const loadExhibitors = async () => {
        try {
            setLoading(true);

            const updatedExhibitors = await getAllExhibitors();
            setExhibitors(updatedExhibitors);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const loadLeads = async () => {
        try {
            setLoading(true);
            await updateToken();
            const updatedLeads = await getLeads();

            const leads = await Promise.all(updatedLeads.map(async (lead) => {
                try {
                    const ticket = await getTicketById(lead.ticketId);
                    return { ...lead, address: { ...ticket } };
                } catch (error) {
                    return { ...lead, address: {} };
                }
            }))

            setLeads(leads);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const downloadLeads = async () => {

        let queryFromDate = new DateTimeManager(fromDate).getDefaultDateFormat()
        let queryToDate = new DateTimeManager(toDate).getDefaultDateFormat()

        await axios
            .get(`/Leads/Export?fromDate=${queryFromDate}&toDate=${queryToDate}`, {
                responseType: "blob",
            })
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Leads_Export.xlsx";
                link.click();
            })
            .catch((error) => console.error(error));
    }

    return (
        <>
            <Navbar />
            {/* <Banner /> */}
            <div className='container mt-4 page-container'>
                <nav className="nav nav-pills nav-fill mb-5">
                    {exhibitor.isAdmin && <button className={"nav-link " + (selectedTab == 0 ? "active" : "")} onClick={() => setSelectedTab(0)}>Aussteller</button>}
                    <button className={"nav-link " + (selectedTab == 1 ? "active" : "")} onClick={() => setSelectedTab(1)}>Leads</button>
                </nav>
                {(selectedTab == 0 && exhibitor.isAdmin) &&
                    (loading ? <Loading /> : <div className='mb-4'>
                        <div className="row">
                            <div className="col-4">
                                <h1 className='text text-dark fw-semibold fs-3 p-1'>Aussteller</h1>
                            </div>
                            <div className="col-8 text-end">
                                <button
                                    className='btn btn-pink'
                                    onClick={() => {
                                        setContext({ selected: 0 })
                                        navigate("/exhibitor")
                                    }}
                                >
                                    Aussteller erfassen
                                    <span><i className="bi bi-plus-circle text-light ms-2"></i></span>
                                </button>
                            </div>
                        </div>
                        {/* <input className='form-control mb-2' placeholder='Suchen' value={filter.textExhibitor} onChange={(e) => setFilter({ ...filter, textExhibitor: e.target.value })} /> */}
                        {exhibitors?.length > 0 && <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Benutzername</th>
                                        <th scope="col">Interne Nummer</th>
                                        <th scope="col">Admin</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        exhibitors?.map((e, index) => (
                                            <tr key={index}>
                                                <td className='col-4'>{e?.username}</td>
                                                <td>{e?.standNumber}</td>
                                                <td>{e?.isAdmin ? "Ja" : "Nein"}</td>
                                                <td><i
                                                    onClick={() => {
                                                        setContext({ selected: 0 })
                                                        navigate(`/exhibitor/${e?.id}`)
                                                    }}
                                                    className="bi bi-pencil"></i></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>}
                    </div>)}
                {(selectedTab == 1) &&
                    (loading ? <Loading /> : <div className='mb-4'>
                        <h1 className='text text-dark fw-semibold fs-3 p-1'>Leads</h1>
                        {/* <input className='form-control mb-2' placeholder='Suchen' value={filter.textLead} onChange={(e) => setFilter({ ...filter, textLead: e.target.value })} /> */}
                        {leads?.length > 0 && <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className='hide-mobile'>Ticket Code</th>
                                        <th scope="col">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        leads?.map((l, index) => (
                                            <tr key={index}>
                                                <td className='hide-mobile'>{l?.address?.uniqueKey}</td>
                                                <td>{l?.email}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className='container text-center p-0'>
                                <button className='success-button' onClick={downloadLeads}>Herunterladen</button>
                            </div>
                        </div>}
                    </div>)}
            </div>
        </>
    )
}

export default Admin