import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import UseStateContext from './session/UseStateContext'
import Navbar from './components/Navbar';
import Banner from './components/Banner';

import errorImg from "../images/error-o.svg";

import { api, getTicketTypeInfosByTicketTypeId, getAddressOfOrder } from 'ticketino-api-client';
import Success from './components/Success';
import Header from './components/Header';
import Loading from './components/Loading';

const Contact = () => {

    const { context, resetContext, setContext } = UseStateContext();

    const [lead, setLead] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sessionError, setSessionError] = useState(false);

    let navigate = useNavigate();
    let baseUrl = "https://api.ticketino.com";

    const {
        salutation,
        firstname,
        lastname,
        company,
        companyFunction,
        street,
        postCode,
        city,
        telephone,
        email,
        projectRequest
    } = lead;

    const { uniqueKey } = useParams();

    useEffect(() => {
        if (!(uniqueKey > 0)) {
            setError(true);
        }
    }, [uniqueKey]);

    useEffect(() => {
        setContext({ uniqueKey: uniqueKey });

        loadToken()
    }, [uniqueKey]);

    const loadToken = async () => {
        await axios.get("Ticketino/Token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            if (uniqueKey > 0) {
                loadTicket(uniqueKey);
            }
        }).catch((error) => {
            logout();
            setError(true);
            setLoading(false);
        })
    }

    const loadTicket = async (uniqueKey) => {
        try {
            setLoading(true);
            setError(false);

            const ticket = await requestTicketByUniqueKey(uniqueKey);

            const ticketTypeInfo = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);

            const ticketTypeName = ticketTypeInfo.find(tti => tti.languageId == 0)?.name;

            const orderAddress = await getAddressOfOrder(ticket.orderId);

            let updatedLead = {
                salutation: orderAddress.salutation,
                firstname: orderAddress.firstName,
                lastname: orderAddress.name,
                company: orderAddress.company,
                companyFunction: orderAddress.function,
                street: orderAddress.street,
                postCode: orderAddress.postCode,
                city: orderAddress.city,
                telephone: orderAddress.telephone,
                email: orderAddress.email,
                customerNumber: orderAddress.code1,
                status: ticket.option11 ?? "",
                ticketId: ticket.id,
                ticketName: ticketTypeName
            }

            setLead(updatedLead);
            setLoading(false);
        }
        catch (error) {
            setError(true);
            setLoading(false);
            console.error(error);
        }
    }

    const requestTicketByUniqueKey = async (uniqueKey) => {
        try {
            const res = await axios.get(`${baseUrl}/Ticket?uniqueKey=${uniqueKey}`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const logout = () => {
        axios.post("/Logout").then(() => {
            resetContext();
            navigate("/login");
        })
    }

    const onInputChange = (e) => {
        setLead({ ...lead, [e.target.name]: e.target.value });
    };

    const onSubmit = () => {
        let updatedLead = {
            exhibitorId: context.exhibitor?.id,
            standNumber: context.exhibitor?.standNumber,
            company: company,
            salutation: salutation,
            firstname: firstname,
            lastname: lastname,
            companyFunction: companyFunction,
            phone: telephone,
            email: email,
            street: street,
            postCode: postCode,
            region: city,
            projectRequest: projectRequest,
            customerNumber: lead.customerNumber,
            status: lead.status,
            ticketId: lead.ticketId,
            scanner: context.scanner,
            ticketName: lead.ticketName
        }

        setContext({ lead: updatedLead });

        if (projectRequest == "project") {
            navigate("/project");
        } if (projectRequest == "customer-visit") {
            navigate("/customer-visit");
        } else if (projectRequest == "information") {
            navigate("/information");
        } else if (projectRequest == "new-customer") {
            navigate("/new-customer");
        } else if (projectRequest == "session-checkin") {
            navigate("/session-checkin");
        }
    }

    // validating input fields
    let validation = projectRequest?.length > 0 && !error;

    return (
        <>
            <Navbar />
            <Banner />
            <div className='container page-container'>
                <Header />
            </div>
            {!loading ? <div className='container mt-4 page-container'>

                {(error && uniqueKey != 0) ? <div className='content m-3 mb-4'>
                    <div className='row'>
                        <div className='col-2 success-contact'><img src={errorImg}></img></div>
                        <div className='col-10 p-0 ps-2'>
                            <h1 className='text text-dark fw-bold fs-5 mb-1'>Scan war nicht erfolgreich</h1>
                            <p className='text text-secondary fs-6 mb-0'>{sessionError ? "Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein." : "Die Ticketnummer existiert nicht oder ist ungültig"}</p>
                        </div>
                    </div>
                </div> : <Success lead={lead} />}

                {!(error && uniqueKey != 0) && <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-2'>Art der Anfrage</h3>

                    <p className='text text-secondary fs-6 mb-3'>Bitte wählen Sie die Art der Anfrage. Sie werden anschliessend auf das richtige Formular weitergeleitet.</p>

                    <div className='mb-4'>
                        <form>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefaultProject" checked={projectRequest == "project"} onClick={() => setLead({ ...lead, projectRequest: "project" })} />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor="flexCheckDefaultProject">
                                    Projektanfrage
                                </label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefaultCustomer" checked={projectRequest == "customer-visit"} onClick={() => setLead({ ...lead, projectRequest: "customer-visit" })} />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor="flexCheckDefaultCustomer">
                                    Kundenbesuch
                                </label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefaultInformation" checked={projectRequest == "information"} onClick={() => setLead({ ...lead, projectRequest: "information" })} />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor="flexCheckDefaultInformation">
                                    Kundeninformation (Infomaterial/Datenupdate)
                                </label>
                            </div><div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefaultNew" checked={projectRequest == "new-customer"} onClick={() => setLead({ ...lead, projectRequest: "new-customer" })} />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor="flexCheckDefaultNew">
                                    Neukunde
                                </label>
                            </div><div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefaultSession" checked={projectRequest == "session-checkin"} onClick={() => setLead({ ...lead, projectRequest: "session-checkin" })} />
                                <label className="form-check-label text-secondary p-1 ms-3" htmlFor="flexCheckDefaultSession">
                                    Session Check-In
                                </label>
                            </div>
                        </form>
                    </div>
                    <button className='button' onClick={onSubmit} disabled={!validation}>Bestätigen</button>
                </div>}
            </div> : <Loading />}
        </>
    )
}

export default Contact